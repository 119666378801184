<template>
<div class="content-template">
  <div class="card animate__animated animate__fadeIn duration" id="fcard1">
      <div class="card_box_one">
        <div class="card_box_title2 card_box_title3">
          <p class="font-blod">80家公司公布<br/>中报业绩预告</p>
          <p>超七成预喜</p>
          <p>{{dataTime}}</p>
        </div>
        <div class="width-100 height-100">
          <video
            src="video/video3.mp4"
            muted
            width="100%"
            height="100%"
            ref="video1"
          ></video>
        </div>
        <div class="logo-box logo-box3">
          <img src="../../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
  <div class="container container2">
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="fcard2"
    >
      <div class="card_two_box">
        <div class="card_header">
          <span class="font-blod">沪深两市</span>
          <span class="line"></span>
          <span>业绩预告披露</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 18px">
          <div class="margin-t-10">
            一季报预告业绩类型(家)
          </div>
          <div id="fchart1" style="width: 100%; height: 860px"></div>
        </div>
        <div class="logo-box">
          <img src="../../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="fcard3"
    >
      <div class="card_three_box">
        <div class="card_header">
          <span class="font-blod">沪深两市</span>
          <span class="line"></span>
          <span>业绩预告披露</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 18px">
          <div class="margin-t-18">
            一季报预告业绩类型
          </div>
          <div class="flex flex-between margin-t-18 notice-box">
            <p class="up-box"><i><img src="../../assets/img/up.png" alt=""></i>预喜率：<span>{{updata}}%</span></p>
            <p class="down-box"><i><img src="../../assets/img/down.png" alt=""></i>预悲率：<span>{{downdata}}%</span></p>
          </div>
          <div id="fchart2" style="width: 100%; height: 800px"></div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="fcard4"
    >
      <div>
        <div class="card_header">
          <span class="font-blod">沪深两市</span>
          <span class="line"></span>
          <span>板块预警</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 18px">
         <div class="margin-t-18 font-18">
            一季报预告业绩类型(家)
          </div>
          <div id="fchart3" style="width: 100%; height: 860px"></div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="fcard5"
    >
     <div class="moveTop">
        <div class="card_header">
          <span class="font-blod">沪深两市</span>
          <span class="line"></span>
          <span>板块预警</span>
          <span>{{dataTime}}</span>
        </div>
        <div>
          <div style="padding: 0px 18px" class="margin-t-18 font-18 none">
            一季报预告业绩类型(家)
          </div>
          <div class="flex data-box margin-t-18">
            <div class="left">
              <p>证券名称</p>
            </div>
            <div class="center flex1">
              <p>同比增长上限及下限(%)</p>
            </div>
            <div class="right">
              <p>所属行业</p>
            </div>
          </div>
          <div class="data-box autoy">
            <div class="flex relative moveUp">
              <div class="left">
                <p v-for="(item,index) in xdata4" :key="index">{{item}}</p>
              </div>
              <div class="center flex1">
                <p v-for="(item,index) in ydata4" :key="index" class="datapercent">{{item}}</p>
              </div>
              <div class="right">
                <p v-for="(item,index) in typedata4" :key="index">{{item}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="fcard6"
    >
     <div class="moveTop">
        <div class="card_header">
          <span class="font-blod">沪深两市</span>
          <span class="line"></span>
          <span>业绩预报披露</span>
          <span>{{dataTime}}</span>
        </div>
        <div>
          <div style="padding: 0px 18px" class="margin-t-18 font-18 none">
            15公司业绩下滑
          </div>
          <div class="flex data-box margin-t-18">
            <div class="left">
              <p>证券名称</p>
            </div>
            <div class="center flex1">
              <p>同比增长上限及下限(%)</p>
            </div>
            <div class="right">
              <p>所属行业</p>
            </div>
          </div>
          <div class="data-box autoy">
            <div class="flex relative movedown">
              <div class="left">
                <p v-for="(item,index) in xdata5" :key="index">{{item}}</p>
              </div>
              <div class="center flex1">
                <p v-for="(item,index) in ydata5" :key="index" class="datadown">{{item}}</p>
              </div>
              <div class="right">
                <p v-for="(item,index) in typedata5" :key="index">{{item}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/logo2.png" alt="" />
      </div>
    </div>
  </div>
  <div class="card animate__animated animate__fadeIn duration none" id="card7">
    <div class="width-100 height-100">
      <video
        class="end_video"
        src="video/end.mp4"
        muted
        width="100%"
        height="100%"
        ref="endVideo"
      ></video>
    </div>
  </div>
</div>
</template>

<script>
import $ from "jquery";
import { GetParams2 } from '@/utils/common'
import ApiServe from '@/api/index'
export default {
  name: "Template3v1",
  data() {
    return {
      // 一季报预告业绩类型
      aduioData:[],
      videoTotalTime:65,
      xdata1: [],
      ydata1: [],
      data2:[],
      updata:null,
      downdata:null,
      xdata3:[],                //一季报预告业绩类型
      xidata3:[10,10,10,10],    //预喜
      beidata3:[5,5,5,5],       //预悲
      undata3:[1,2,2,2,2],      //不确定
      xdata4:[],
      ydata4:[],
      typedata4:[],
      xdata5:[],
      ydata5:[],
      typedata5:[],
      chart_data_1:[],
      record_time:65,
      dataTime:'',
      id:'',
      model_id:null,
      model_name:"",
      model_category:'',
      url_extra_params:null,
      ip:'',
      port:'',
      offset_x:'',
      offset_y:'',
      width:"",
      height:'',
      source:''
    };
  },
  created() {
    this.id = GetParams2().id || null
    this.model_id = GetParams2().model_id || 3
    this.model_name = GetParams2().model_name || null
    this.model_category = GetParams2().model_category || null
    this.url_extra_params = GetParams2().url_extra_params || null
    this.ip = GetParams2().ip || null
    this.port = GetParams2().port || null
    this.offset_x = GetParams2().offset_x || null
    this.offset_y = GetParams2().offset_y || null
    this.width = GetParams2().width || null
    this.height = GetParams2().height || null
    this.source = GetParams2().source || null
    this.getData();
    this.getAudio()
  },
  computed:{
    allReady(){
      const { chart_data_1, aduioData } = this;
      return {
        chart_data_1,
        aduioData,
      };
    },
    newIpPort(){
      if(this.source == 'ali'){
        return 'https://recordvideoali.wedengta.com'
      }else{
        return 'https://recordvideo.wedengta.com'
      }
    }
  },
  watch:{
    allReady(val){
      if(val.chart_data_1.length>0 && val.aduioData.length>0){
        if (this.ip && this.port) {
          const params = {
            ip:this.ip,
            port:this.port,
            offset_x:this.offset_x,
            offset_y:this.offset_y,
            width:this.width,
            height:this.height
          }
          ApiServe.recordstartPc(this.newIpPort,params).then(({code}) => {
            
          }).catch((err)=>{
            ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
          });
          this.load();
        } else {
          alert('没获取到ip,port')
          this.load();
        }
      }
    }
  },
  methods: {
    getData() {
      let params = {
        model_id: this.model_id
      }
      if(this.url_extra_params){
        params = {
          model_id: this.model_id,
          url_extra_params:this.url_extra_params
        }
      }
      ApiServe.getDataPC(params).then(({data,code})=>{
          if(code == 200){
            this.dataTime = data.extra_param_3[0]
            this.chart_data_1 = data.chart_data_1
            this.xdata1 = data.chart_data_1[0].data.x
            this.ydata1 = data.chart_data_1[0].data.y
            // 自己组装数据
            this.data2 = data.chart_data_2[0].data.x.map((item,index)=>{
              return {
                value:Number(data.chart_data_2[0].data.y[index]),
                name:item
              }
            })
            // 数组里面后端返回的顺序不固定，只能这样取
            this.updata = this.data2.filter((item)=> item.name == '预喜')[0].value.toFixed(2)
            this.downdata = this.data2.filter((item)=> item.name == '预悲')[0].value.toFixed(2)
            // 一季报预告业绩类型
            this.xdata3 = data.chart_data_3[0].data.x
            this.xidata3 = data.chart_data_3[0].data['预喜']
            this.beidata3 = data.chart_data_3[0].data['预悲']
            this.undata3 = data.chart_data_3[0].data['不确定']
            this.xdata4 = data.chart_data_4[0].data['股票名称']
            this.ydata4 = data.chart_data_4[0].data['同比增长下限及上限']
            this.typedata4 = data.chart_data_4[0].data['所属行业']
            this.xdata5 = data.chart_data_5[0].data['股票名称']
            this.ydata5 = data.chart_data_5[0].data['同比增长下限及上限']
            this.typedata5 = data.chart_data_5[0].data['所属行业']
            if (this.ip && this.chart_data_1.length==0) {
              ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
            }
          }  
      }).catch((err)=>{
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
          }
        })
    },
    // 获取旁白
    getAudio(){
      let params = {
        model_id: this.model_id
      }
      if(this.url_extra_params){
        params = {
          model_id: this.model_id,
          url_extra_params:this.url_extra_params
        }
      }
      ApiServe.getVideoDataPC(params).then(({data,code,dd})=>{
        if(code == 200){
          this.aduioData = data || []
          if (this.ip && this.aduioData.length ==0) {
            ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
          }
        }
      }).catch((err)=>{
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip,port:this.port });
          }
        });
    },
    // 一季报预告业绩类型
    loadChart1() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("fchart1"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "120px",
          top: "40px",
          bottom: "52px",
          right: "80px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            show:false,
          },
        ],
         yAxis: [
          {
            type: "category",
            data: this.xdata1,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              color:'#AFF7FF',
              fontSize:24
            }
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 48,
            data: this.xdata1.map((item,index)=>{
              return {
                value: this.ydata1[index],
                label: {
                  // 设置显示label
                  show: true,
                  // 设置label的位置
                  position: "right",
                  // 设置label的文字颜色
                  color: '#AFF7FF',
                  // 格式化label文字
                  // formatter: function (value) {
                  //   return Math.abs(value).toFixed(2);
                  // },
                  textStyle: { //数值样式
                    fontSize: 28
                  },
                },
                itemStyle: {
                  color: (item == '预增' || item == '略增' || item == '续盈' || item == '扭亏') ? "#E54444" : (item == '略减' || item == '预减' || item == '首亏' || item == '增亏' || item == '续亏') ? "#00B466" : '#9A9A9A',
                },
              };
            }),
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 一季报预告业绩类型
    loadChart2() {
      var colorList = ['#E54444','#00B466','#9A9A9A']
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("fchart2"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "120px",
          top: "40px",
          bottom: "52px",
          right: "0",
        },
        animationDuration: 4000,
        series: [
          {
            type: "pie",
            data: this.data2,
            radius: '50%',
            center: ['50%', '40%'],
            itemStyle: {
              normal: {
                label: {
                  textStyle: {
                    //数值样式
                    color: "#AFF7FF",
                    fontSize: 28,
                  },
                  formatter: function (value) {
                    return value.name+':'+'\n'+ (value.value).toFixed(2)+'%';
                  },
                },
                color:function(params){
                  return params.name == '预喜' ? colorList[0] : params.name == '预悲' ? colorList[1] :colorList[2]
                },
                labelLine:{
                  smooth:true
                }
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 一季报预告业绩类型
    loadChart3() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("fchart3"));
      // 指定图表的配置项和数据
      var option = {
        legend: {
          data: ['预喜','预悲','不确定'],
          textStyle: {
            color: "#AFF7FF",
            fontSize:24
          },
          right:0,
          top:0,
          width:80,
          itemWidth: 20,
          itemHeight:20,
        },
        grid: {
          left: "120px",
          top: "120px",
          bottom: "52px",
          right: "100px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            show:false,
          },
        ],
        yAxis: [
          {
            type: "category",
            data: this.xdata3,
            axisLine: { show: true,lineStyle:{color:"#9B9B9B"} },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              color:'#AFF7FF',
              fontSize:24
            }
          },
        ],
        series: [
          {
            name: "预喜",
            type: "bar",
            data: this.xidata3,
            itemStyle: {
              normal: {
                color: "#E54444",
              },
            },
          },
          {
            name: "预悲",
            type: "bar",
            data: this.beidata3,
            itemStyle: {
              normal: {
                color: "#00B466",
              },
            },
          },
          {
            name: "不确定",
            type: "bar",
            data: this.undata3,
            itemStyle: {
              normal: {
                color: "#9A9A9A",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    endRecord(){
      let arr =[3,19,25,36,51]
      let audiosOrderList =  this.aduioData.map((item,index)=>{
        return {
          'audioLink':item.audio_link,
          'timeIndex':arr[index],
          'audioLen':item.audio_length,
        }
      })
      const data = {
        'id':this.id, 
        'model_id':this.model_id,
        'videoTotalTime':this.videoTotalTime,
        'videmBgm':"https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        'audiosOrderList':audiosOrderList
      }
      ApiServe.recordstopPc(this.newIpPort,{ip:this.ip,port:this.port}).then(()=>{
        ApiServe.combineaudioswithbgm(this.newIpPort,this.ip,this.port,data).then(()=>{
           ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
        }).catch(()=>{
          ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
        })
      }).catch(()=>{
        ApiServe.recordreleasePc(this.newIpPort,{ip:this.ip,port:this.port})
      })
    },
    load() {
      this.$refs.video1.play()  //首页的视频播放
      setTimeout( ()=> {
        $("#fcard1").addClass("animate__fadeOut");
      }, 2000);
      setTimeout(() => {
        $("#fcard1").addClass("none");
        $("#fcard2").removeClass("none");
        this.loadChart1();
      }, 3000);
      setTimeout( ()=> {
        $("#fcard2").addClass("animate__fadeOut");
      }, 18000);
      setTimeout( ()=> {
        $("#fcard2").addClass("none");
        $("#fcard3").removeClass("none");
        this.loadChart2();
      }, 19000);
      setTimeout( ()=> {
        $("#fcard3").addClass("animate__fadeOut");
      }, 24000);
      setTimeout( ()=> {
        $("#fcard3").addClass("none");
        $("#fcard4").removeClass("none");
        this.loadChart3();
      }, 25000);
      setTimeout( ()=> {
        $("#fcard4").addClass("animate__fadeOut");
      }, 35000);
      setTimeout( ()=> {
        $("#fcard4").addClass("none");
        $("#fcard5").removeClass("none");
        if(this.xdata4.length>10){
          let topPx = (this.xdata4.length -10)*40
          $('.moveUp').animate({top:topPx},8000)
        };
      }, 36000);
      setTimeout( ()=> {
        $("#fcard5").addClass("animate__fadeOut");
      }, 50000);
      setTimeout( ()=> {
        $("#fcard5").addClass("none");
        $("#fcard6").removeClass("none");
        if(this.xdata5.length>10){
          let topPx = (this.xdata5.length -10)*40
          $('.movedown').animate({top:topPx},8000)
        };
      }, 51000);
      setTimeout( ()=> {
        $("#fcard6").addClass("animate__fadeOut");
      }, 59000);
      setTimeout( ()=> {
        $("#fcard6").addClass("none");
        $("#card7").removeClass("none");
        this.$refs.endVideo.play()
      }, 60000);
      // 总共65秒
      if(this.ip){
        setTimeout( ()=> {
          this.endRecord()
        }, 65000); 
      }
 
      
    },
  },
};
</script>
<style lang="less" scoped>
@import '@/assets/css/common.less';
  .font-18{
    font-size: 18px*@beishu;
  }
  .logo-box{
    position: absolute;
    width: 210px*@beishu;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
  }
  .card{
    font-size: 14px*@beishu;
  }
  .logo-box3{
    bottom: 5%;
  }
  .container {
    width: 100%;
    height: 95%;
    border: 1px cadetblue;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .card_box_title3{
    width: 250px;
    height: 280px;
  }
  .card_header{
      height: 60px*@beishu;
      line-height: 60px*@beishu;
      padding: 0 18px;
      background-color: #172A53;
      color: #AFF7FF;
  }
  .card_header span.line{
      display: inline-block;
      width: 2px;
      height: 16px*@beishu;
      background-color: #ddd;
      margin: 0 6px*@beishu;
  }
  .card_header span:first-of-type{
      font-size: 22px*@beishu;
      /* border-right: 2px solid #ccc;
      padding-right: 10px; */
  }
  .card_header span:last-of-type{
      float: right;
  }
  .notice-box{
    height: 94px;
    background: linear-gradient(180deg, #2B3560 0%, #1F243B 100%);
    border-radius: 20px;
    line-height: 94px;
  }
  .notice-box p{
    width: 50%;
    /* font-size: 16px; */
    text-align: center;
    position: relative;
  }
  .notice-box p i{
    position: absolute;
    left: 5px;
    top: 12px;
    width: 30px;
    height: 30px;
  }
  .notice-box p img{
    width: 100%;
    height: auto;
  }
  .notice-box p.up-box span{
    color: #E54444;
    font-size: 18px*@beishu;
  }
  .notice-box p.down-box span{
    color: #00B466;
    font-size: 22px*@beishu;
  }
  .moveTop .left,.moveTop .right{
    width: 100px*@beishu;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .moveTop .data-box p{
    height: 84px;
    line-height: 84px;
    text-align: center;
  }
  .moveTop .center{
    border-left:1px solid #9B9B9B;
    border-right: 1px solid #9B9B9B;
  }
  .autoy{
    height: 840px;
    overflow: hidden;
  }
  .data-box .datapercent{
    color: #E54444;
  }
  .data-box .datadown{
    color: #00B466;
  }
  .card_box_title2{
    position: absolute;
    left: 50%;
    top: 40%;
    transform:translate(-50%,-50%);
    width: 250px*@beishu;
    height: 230px*@beishu;
    border: 1px solid #85DEFF;
    text-align: center;
  }
  .card_box_title2 p:first-of-type{
    font-size: 36px*@beishu;
  }
  .card_box_title2 p:nth-of-type(2){
    font-size: 30px*@beishu;
  }
  .card_box_title2 p{
    margin: 16px*@beishu 0;
  }
  .card_box_title2 p:nth-of-type(3){
    font-size: 18px*@beishu;
    margin-top: 26px*@beishu;
  }
</style>